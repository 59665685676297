'use client';

import { useEffect, useState } from 'react';
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from '@/components/ui/carousel';
import { useTranslations } from 'next-intl';
import SectionHeader from './section-header';
import { useRecentlyViewed } from '@/contexts/recenty-viewed.context';

export default function RecentlyViewedBlock() {
  const t = useTranslations();
  const { recentlyViewedProducts, isLoading, isError, loadRecentlyViewedProducts } = useRecentlyViewed();

  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setTimeout(() => {
      if (api.selectedScrollSnap() + 1 === api.scrollSnapList().length) {
        setCurrent(0);
        api.scrollTo(0);
      } else {
        api.scrollNext();
        setCurrent(current + 1);
      }
    }, 4000);
  }, [api, current]);

  if (recentlyViewedProducts.length === 0) return null;

  return (
    <>
      <SectionHeader heading={t('recently_viewed')} url="/search?sort=recently_viewed" />
      <div className="w-full py-5 lg:py-10">
        <div className="container mx-auto">
          <div className="flex flex-col gap-10">
            <Carousel setApi={setApi} className="w-full">
              <CarouselContent>
                {Array.from({ length: 15 }).map((_, index) => (
                  <CarouselItem className="basis-1/4 lg:basis-1/6" key={index}>
                    <div className="flex aspect-square items-center justify-center rounded-md bg-muted p-6">
                      <span className="text-sm">Logo {index + 1}</span>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
