'use client';

import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import { HeartIcon, ShoppingCartIcon } from 'lucide-react';
import { IProduct } from '@/types/types';
import { useTranslations } from 'next-intl';
import { buildProductUrl } from '@/lib/link-manager';
import { Level } from '@/lib/api/types/api.enums';
import LevelBadge from './level-badge';
import { motion } from 'framer-motion';
import { settings } from '@/config';

interface ProductCardProps {
  product: IProduct;
  isInCart: boolean;
  isInWatchlist: boolean;
  toggleCart: (product: IProduct) => void;
  toggleWatchlist: (product: IProduct) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, isInCart, isInWatchlist, toggleCart, toggleWatchlist }) => {
  const t = useTranslations();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, ease: 'easeOut' }}
      className="rounded-lg shadow-sm"
    >
      <div className="rounded-lg p-2 hover:shadow-md">
        <div className="relative aspect-square w-full overflow-hidden rounded-md">
          <Link href={buildProductUrl(product.id, product.slug)} className="cursor-pointer">
            <Image
              src={product.image_url}
              alt={product.name}
              layout="fill"
              objectFit="cover"
              className="rounded-md"
            />
          </Link>

          {product.flags.is_shipping_free && (
            <LevelBadge level={Level.SUCCESS}>{t('free_shipping')}</LevelBadge>
          )}

          <Button
            variant="ghost"
            size="icon"
            onClick={() => toggleWatchlist(product)}
            className={`absolute top-2 right-2 rounded-full p-1 ${isInWatchlist ? 'bg-white shadow-lg' : 'bg-white/50'}`}
          >
            <HeartIcon fill={isInWatchlist ? '#FF4500' : 'none'} stroke={isInWatchlist ? '#FF4500' : 'currentColor'} className="border-muted-foreground" />
          </Button>
        </div>

        <div className="mt-2">
          <Link href={buildProductUrl(product.id, product.slug)} className="cursor-pointer" title={product.name} >
            <span className="line-clamp-2 block max-w-full overflow-hidden text-md leading-[1.35] hover:underline">
              {product.name}
            </span>
          </Link>
        </div>

        <div className="mt-1 text-2xl font-semibold">
          {product.price.discount < settings.discountDisplayThreshold ? (
            <div>
              <s>{product.price.initial}</s>
              <span className="ml-1 text-destructive">${product.price.current}</span>
            </div>
          ) : (
            <span className="text-wlampi">${product.price.current}</span>
          )}
        </div>
        <Button variant="ghost" size="icon" onClick={() => toggleCart(product)}>
          <ShoppingCartIcon fill={isInCart ? '#FF4500' : 'none'} className="border-muted-foreground"/>
        </Button>
      </div>
    </motion.div>
  );
};

export default React.memo(ProductCard);
