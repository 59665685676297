'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useWatchlist } from '@/contexts/watchlist.context';
import { ApiException } from '@/lib/api/exceptions/api-exception';
import { IApiException } from '@/lib/api/types/api.types';
import { IProduct } from '@/types/types';
import { useTranslations } from 'next-intl';
import { endpoints } from '@/config/endpoints';
import { useCart } from '@/hooks/useCart';
import { useUi } from '@/contexts/ui.context';
import { buildApiUrl, buildRequest, handleApiResponse } from '@/lib/api/http/http.index';
import { buildHeaders, sendRequest } from '@/lib/api/http/http.client';
import { logError } from '@/lib/api/logger/logger.client';
import ProductCard from './product-card';
import ScrollToTopButton from '../buttons/scroll-to-top-button';

type ProductListProps = {
  initialProducts: IProduct[];
  isPreLoading: boolean;
};

const apiEndpoint = endpoints.content.products.INDEX;

const ProductGrid = ({ initialProducts, isPreLoading }: ProductListProps) => {
  const t = useTranslations();
  const { isDesktop } = useUi();
  const { isInWatchlist, toggleWatchlist } = useWatchlist();
  const { toggleCart, isInCart } = useCart();
  const [products, setProducts] = useState<IProduct[]>(initialProducts);
  const limit = isDesktop ? 24 : 12;
  const [offset, setOffset] = useState(products.length || limit);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isLoading, setIsLoading] = useState(isPreLoading);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const headers = buildHeaders('GET');

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const controller = new AbortController();
      const apiUrl = buildApiUrl(apiEndpoint, { offset, limit, order_dir: 'desc' });
      const request = buildRequest('GET', headers);
      const apiResponse = await sendRequest<IProduct[] | IApiException>(apiUrl, request, controller);
  
      if (apiResponse.success) {
        const loadedProducts = apiResponse.data as IProduct[];
        if (loadedProducts.length > 0) {
          setOffset((prev) => prev + limit);
          setProducts((prev) => [...prev, ...loadedProducts]);
        } else {
          setHasMore(false);
        }
      } else {
        throw new ApiException(apiResponse.data as IApiException);
      }
    } catch (error: unknown) {
      logError(error, { details: 'failed on loading products' });
    } finally {
      setIsLoading(false);
    }
  }, [offset, limit, headers]);

  const lastProductRef = useCallback((node: HTMLSpanElement | null) => {
    if (isLoading || !hasMore || !node) return;
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting && hasMore && !isLoading) {
        fetchProducts();
      }
    }, { threshold: 1.0 });

    observerRef.current.observe(node);
  }, [isLoading, hasMore]);

  useEffect(() => {
    return () => observerRef.current?.disconnect();
  }, []);

  return (
    <div className="lg-mid:grid-cols-5 grid auto-rows-min grid-cols-2 justify-center gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 lg:gap-5">
      {products.length > 0 ? (
        <>
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              isInCart={isInCart(product.id)}
              isInWatchlist={isInWatchlist(product.id)}
              toggleCart={toggleCart}
              toggleWatchlist={toggleWatchlist}
            />
          ))}

          {hasMore ? (
            <span
              ref={lastProductRef}
              aria-hidden="true"
              className="block h-1 w-full opacity-0"
            />
          ) : (
            <div className="col-span-full flex justify-center py-6">
              <ScrollToTopButton />
            </div>
          )}

          {isLoading && (
            <div className="col-span-full flex justify-center py-4">
              <Loader2 className="animate-spin" />
            </div>
          )}
        </>
      ) : isLoading ? (
        <div className="col-span-full flex justify-center py-4">
          <Loader2 className="animate-spin" />
        </div>
      ) : (
        <p className="text-center col-span-full">{t('no_products')}</p>
      )}
    </div>
  );
};

export default ProductGrid;
