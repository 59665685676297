'use client';

import Image from 'next/image';
import { Skeleton } from "../ui/skeleton";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { useTranslations } from "next-intl";
import SectionHeader from './section-header';
import { useWatchlist } from '@/contexts/watchlist.context';

export default function WatchlistBlock() {
  const t = useTranslations();
  const { watchlistCount, watchlistProducts, isLoading } = useWatchlist();

  if (watchlistProducts.length === 0) return null;

  return (
    <div className="bg-muted/40 border border-border rounded-xl px-5 py-6 mb-10 shadow-sm">
      <SectionHeader heading={t('watchlist')} url="/search?sort=watchlist" />
      <ScrollArea className="w-full mt-4">
        <div className="flex space-x-4">
          {isLoading ?? watchlistProducts.length === 0 ? (
            Array.from({ length: watchlistCount }).map((_, idx) => (
              <div key={idx} className="h-[200px] w-[200px] flex-shrink-0">
                <Skeleton className="h-full w-full rounded-md" />
              </div>
            ))
          ) : watchlistProducts.length > 0 ? (
            watchlistProducts.map(watchlistProduct => (
              <div key={watchlistProduct.id} className="relative w-[200px] h-[200px] flex-shrink-0 overflow-hidden rounded-md shadow-sm group">
                <Image
                  src={watchlistProduct.image_url}
                  alt={watchlistProduct.name}
                  height={200}
                  width={200}
                  className="object-cover h-full w-full group-hover:scale-105 transition-transform"
                />
                <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 transition-colors" />
                <span className="absolute bottom-2 left-2 right-2 text-sm text-white font-medium truncate z-10">
                  {watchlistProduct.name}
                </span>
              </div>
            ))
          ) : null}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
}