import React, { CSSProperties, JSX } from 'react';
import { cn } from '@/lib/utils';

type Variant = 'mediumHeading' | 'heading' | 'body' | 'pageHeading' | 'subHeading';

interface TextCustomProps {
  variant?: Variant;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  html?: string;
}

const componentsMap: Record<Variant, keyof JSX.IntrinsicElements> = {
  pageHeading: 'h1',
  subHeading: 'h2',
  mediumHeading: 'h3',
  heading: 'h4',
  body: 'p',
};

const TextCustom = ({ style, className, variant = 'body', children, html }: TextCustomProps) => {
  const Component = componentsMap[variant];

  return (
    <Component
      className={cn(
        {
          'text-sm leading-7 sm:leading-6': variant === 'body',
          'text-body': variant === 'body',
          'text-[22px] sm:text-[24px] md:text-[26px] lg:text-[28px] leading-[31px] font-bold text-[#212121]':
            variant === 'mediumHeading',
          'text-heading': [
            'mediumHeading',
            'heading',
            'pageHeading',
            'subHeading',
          ].includes(variant),
          'text-sm font-semibold md:text-base xl:text-lg':
            variant === 'heading',
          'text-2xl font-bold': variant === 'pageHeading',
          'text-lg font-bold md:text-2xl xl:text-3xl 2xl:text-4xl':
            variant === 'subHeading',
        },
        className,
      )}
      style={style}
      {...(html ? { dangerouslySetInnerHTML: { __html: html } } : {})}
    >
      {!html && children}
    </Component>
  );
};

export default TextCustom;
