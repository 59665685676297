'use client';

import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { Button } from '../ui/button';
import { useRouter } from 'next/navigation';

const slides = [
  '/assets/images/banners/banner-1.jpg',
  '/assets/images/banners/banner-2.jpg',
  '/assets/images/banners/banner-3.jpg',
];

export default function HeroBanner() {
  const router = useRouter();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <section className="w-full max-w-screen-2xl mx-auto px-4 py-6">
      <div className="flex flex-col md:flex-row gap-4 items-stretch">
        <div className="h-[200px] md:h-[300px] w-full md:w-[300px] rounded-md bg-muted flex items-center justify-center">
          <span className="text-sm text-muted-foreground">Left Ad</span>
        </div>

        <div className="relative overflow-hidden h-[200px] md:h-[300px] flex-grow">
          <div className="embla h-full" ref={emblaRef}>
            <div className="embla__container flex h-full">
              {slides.map((src, index) => (
                <div className="embla__slide min-w-full h-full relative" key={index}>
                  <Image
                    src={src}
                    alt={`Banner ${index + 1}`}
                    fill
                    className="object-cover rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-2">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => emblaApi?.scrollTo(index)}
                className={`w-2 h-2 rounded-full transition-all ${selectedIndex === index ? 'bg-orange-500 scale-125' : 'bg-gray-400'}`}
              />
            ))}
          </div>
        </div>

        <div className="h-[200px] md:h-[300px] w-full md:w-[300px] rounded-md bg-muted flex items-center justify-center">
          <span className="text-sm text-muted-foreground">Right Ad</span>
        </div>
      </div>
    </section>
  );
}
