'use client';

import Image from 'next/image';

export default function ParallaxSection() {
  return (
    <div className="relative h-[400px] overflow-hidden">
      <div
        className="absolute inset-0 bg-fixed bg-center bg-cover"
        style={{
          backgroundImage: "url('/assets/images/banners/banner-6.jpg')",
        }}
      />
      <div className="relative z-10 flex h-full items-center justify-center bg-black/30">
        <h2 className="text-3xl font-bold text-white drop-shadow-md">Discover Something New</h2>
      </div>
    </div>
  );
}