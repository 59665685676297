'use client';

import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { ArrowUp } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ScrollToTopButtonProps {
  showAfterScrollY?: number;
  alwaysVisible?: boolean;
  className?: string;
}

export default function ScrollToTopButton({
  showAfterScrollY = 300,
  alwaysVisible = false,
  className,
}: ScrollToTopButtonProps) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (alwaysVisible) {
      setVisible(true);
      return;
    }

    const onScroll = () => {
      setVisible(window.scrollY > showAfterScrollY);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [alwaysVisible, showAfterScrollY]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button
      onClick={handleScrollTop}
      size="icon"
      variant="secondary"
      className={cn(
        'fixed bottom-5 right-5 z-50 rounded-full shadow-md transition-all duration-300 ease-in-out',
        visible ? 'opacity-100 translate-y-0 pointer-events-auto' : 'opacity-0 translate-y-5 pointer-events-none',
        className
      )}
    >
      <ArrowUp className="h-5 w-5" />
    </Button>
  );
}
