import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { Button } from '../ui/button';
import TextCustom from '../custom/text-custom';

interface Props {
  heading: string;
  url?: string;
  className?: string;
  textClassName?: string;
}

const SectionHeader: React.FC<Props> = ({ heading, url, className = 'mb-1 sm:mb-2 md:mb-3', textClassName = '' }) => {
  const t = useTranslations();

  return (
    <div className={`px-2 flex items-center justify-between ${className}`}>
      <TextCustom className={textClassName} variant="mediumHeading">{heading}</TextCustom>
      {url && (
        <Button asChild variant="outline">
          <Link href={url}>
            {t('see_all')}
          </Link>
        </Button>
      )}
    </div>
  );
};

export default SectionHeader;
