'use client';

import { Skeleton } from "../ui/skeleton";
import { useTranslations } from "next-intl";
import { useTaxonomy } from '@/contexts/taxonomy.context';
import { getCategoryIcon } from '@/lib/icons-manager';
import Link from "next/link";
import { buildCategoryLink } from "@/lib/link-manager";

export default function CategoriesSection() {
  const t = useTranslations();
  const { mainCategories, isLoading } = useTaxonomy();

  return (
    <section className="my-8">
      <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 gap-6">
        {isLoading
          ? Array.from({ length: 12 }).map((_, idx) => (
            <div key={idx} className="flex flex-col items-center space-y-2">
              <Skeleton className="rounded-full w-16 h-16" />
              <Skeleton className="w-20 h-4" />
            </div>
          ))
          : mainCategories.map((category) => (
            <Link
              key={category.category_id}
              href={buildCategoryLink(category.slug, category.category_id)}
              className="group flex flex-col items-center space-y-2 transition hover:scale-[1.03]"
            >
              <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center transition group-hover:bg-primary/20">
                {getCategoryIcon(category.slug, 32, 'currentColor')}
              </div>
              <span className="text-sm text-center font-medium text-foreground group-hover:text-primary">
                {category.name}
              </span>
            </Link>
          ))}
      </div>
    </section>
  );
}