import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/frontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/custom/product-grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/sections/categories-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/sections/hero-banner-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/sections/parallax-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/sections/recently-viewed-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/components/sections/watchlist-block.tsx");
