'use client';

import { Level } from '@/lib/api/types/api.enums';
import React from 'react';
import { Badge } from '../ui/badge';

interface LevelBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  level: Level;
  children: React.ReactNode;
}

const levelColors: Record<Level, { bg: string; text: string }> = {
  critical: { bg: 'bg-red-500/50', text: 'text-red-700' },
  alert: { bg: 'bg-orange-500/50', text: 'text-orange-700' },
  error: { bg: 'bg-orange-500/50', text: 'text-orange-700' },
  warning: { bg: 'bg-yellow-500/50', text: 'text-yellow-700' },
  info: { bg: 'bg-blue-500/50', text: 'text-blue-700' },
  success: { bg: 'bg-green-500/50', text: 'text-green-700' },
  debug: { bg: 'bg-zinc-500/50', text: 'text-zinc-700' },
};

const LevelBadge: React.FC<LevelBadgeProps> = ({ level, children, className = '', ...props }) => {
  const colors = levelColors[level];

  return (
    <Badge className={`rounded-md px-2 py-1 ${colors.bg} ${colors.text} ${className}`} {...props}>
      {children}
    </Badge>
  );
};

export default LevelBadge;
